
<p-card class="text-center">
  @for (tenant of tenantList; track $index; ) {
    <h2 class="mb-4"> {{ tenant.name }} </h2>
  }
  <p-button severity="info" size="small" (click)="getShortToken()" >Login</p-button>
</p-card>
<div class="rightWrap grid flex-column align-items-end ">
  <div class="absolute right-0  top-0 h-5rem w-5rem  align-items-end justify-content-end py-4 user-section ml-5">
    <p-tieredMenu #menu [model]="items" [popup]="true" appendTo="body"></p-tieredMenu>
    <button
      type="button"
      (click)="menu.toggle($event)"
      class="user-initials"
      [ngStyle]="{ 'border': 'none', 'outline': 'none' }"
    >
      <div>{{initials}}</div>
    </button>
  </div>
</div>
