import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { COMMON_CONSTANT } from '../../constants/common-keys.constants';
import { DomainAvailabilityRequest, SignUpRequest, TenantReponse, TenantRequest, VerifyAccountRequest, VerifyAccountResponse } from '../../../shared/interfaces/signup/sign-up.interface';

@Injectable()
export class DomainService {

  private apiBaseUrl = COMMON_CONSTANT.API_BASE_URL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  }

  constructor(private _http: HttpClient) {}

  signup(signupData: SignUpRequest): Observable<{'token': string}> {
    return this._http.post<{'token': string}>(`${this.apiBaseUrl}/signup`, signupData, this.httpOptions);
  }

  verifyAccount(verifyPayload: VerifyAccountRequest): Observable<VerifyAccountResponse> {
    return this._http.post<VerifyAccountResponse>(`${COMMON_CONSTANT.API_BASE_URL}/verify-account`, verifyPayload, this.httpOptions);
  }

  checkDomainAvailability(verifyPayload: DomainAvailabilityRequest): Observable<{'status': 'AVAILABLE' | 'TAKEN'}> {
    return this._http.post<{'status': 'AVAILABLE' | 'TAKEN'}>(`${COMMON_CONSTANT.API_BASE_URL}/domain/availability/check`, verifyPayload, this.httpOptions);
  }

  addTenant(tenantData: TenantRequest): Observable<TenantReponse> {
    return this._http.post<TenantReponse>(`${COMMON_CONSTANT.API_BASE_URL}/tenants`, tenantData, this.httpOptions);
  }

  requestCrossToken(data: {'subdomain': string}): Observable<{'shortToken': string}> {
    return this._http.post<{'shortToken': string}>(`${COMMON_CONSTANT.API_BASE_URL}/cross-token`, data, this.httpOptions);
  }

  getTenantData(): Observable<TenantReponse[]> {
    return this._http.get<TenantReponse[]>(`${COMMON_CONSTANT.API_BASE_URL}/tenants`);
  }

}
