import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AutoFocusModule } from 'primeng/autofocus';
import { LayoutOrderSwitcherService } from '../../core/services/layout-order/layout-order-switcher.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginService } from '../../core/services/login/login.service';
import { SessionService } from '../../core/services/session/session.service';
import { ToastService } from '../../core/services/toaster-service/toast.service';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-login-page',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ButtonModule, AutoFocusModule, RouterLink, NgIf],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  providers: [ToastService]
})
export class LoginPageComponent implements OnInit {

  loginForm!: FormGroup;
  loading: boolean = false;
  redirectUrl: string = '';
  errorMessage: string = '';

  constructor(private _fb: FormBuilder, private _layoutOrderSwitcherService: LayoutOrderSwitcherService, private _activatedRoute: ActivatedRoute, private _loginService: LoginService, private _sessionService: SessionService, private _router: Router, private _toastService: ToastService) {}

  ngOnInit(): void {
    this._layoutOrderSwitcherService.setNewOrder(1);
    this.constructorForm();
    this.autoRedirection();
  }

  constructorForm() {
    this.loginForm = this._fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  autoRedirection() {
    this._activatedRoute.queryParams.subscribe(params => {
      const redirectParam = params['redirectURL'];
      if (redirectParam) {
        this.redirectUrl = decodeURIComponent(decodeURIComponent(redirectParam));
      }
    });
  }

  onSubmit(): void {
    if (this.loginForm.valid) {
      this.loading = true;
      this.errorMessage = '';
      this.loginForm.disable();
      this._loginService.login(this.loginForm.value).subscribe({
        next: (res) => {
          this._sessionService.createSession(res.access_token, res.refresh_token);
          setTimeout(() => {
            if (this.redirectUrl) {
              this._toastService.showSuccess({
                summary: 'Login Successful',
                detail: 'Redirecting you...',
              });
              this._router.navigateByUrl(this.redirectUrl);
            } else {
              this._router.navigate(['my-domains']);
            }
          }, 500);
        },
        error: (err) => {
          this.loading = false;
          this.loginForm.enable();
          if (err.status === 400) {
            this.errorMessage = 'Invalid credentials. Please try again.';
            this.loginForm.enable();
          } else if (err.status === 500) {
            this.errorMessage = 'Server issue. Please try again later.';
            this.loginForm.enable();
          } else {
            this.errorMessage = 'An unexpected error occurred.';
            this.loginForm.enable();
          }
          this._toastService.showError({
            summary: 'Login Failed',
            detail: this.errorMessage,
          });
        },
        complete: () => {
          this.loading = false;
          this.loginForm.enable();
        },
      });
    }
  }
}
