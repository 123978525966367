import { Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { SignupPageComponent } from './pages/signup-page/signup-page.component';
import { GuardRedirectGuard } from './core/guards/guard-redirection/guard-redirect.guard';
import { DomainListingPageComponent } from './pages/domain-listing-page/domain-listing-page.component';
import { CreateworkspacePageComponent } from './pages/createworkspace-page/createworkspace-page.component';

export const routes: Routes = [
  { path: '', redirectTo: '/sign-up', pathMatch: 'full' },
  { path: 'sign-up', component: SignupPageComponent, title: 'SignUp', canActivate: [GuardRedirectGuard] },
  { path: 'login', component: LoginPageComponent, title: 'Login', canActivate: [GuardRedirectGuard] },
  { path: 'create-workspace', component: CreateworkspacePageComponent, title: 'Create your workspace', canActivate: [AuthGuard] },
  { path: 'my-domains', component: DomainListingPageComponent, title: 'Domain Listing', canActivate: [AuthGuard] },
];
