import { ValidatorFn, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export function matchPasswordsValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);
    if (!control || !matchingControl) {
      console.error('Form controls can not be found in the form group');
      return { controlNotFound: false };
    }
    if (matchingControl.errors && !matchingControl.errors['passwordMismatch']) {
      return null;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      if (matchingControl.errors) {
        delete matchingControl.errors['passwordMismatch'];
        if (Object.keys(matchingControl.errors).length === 0) {
          matchingControl.setErrors(null);
        }
      }
    }
    return null;
  };
}
