import { Observable } from 'rxjs';
import { Injectable, inject } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateFn } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
class GuardRedirectionService {

  constructor(private _sessionService: SessionService, private router: Router) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this._sessionService.getAccessToken()) {
      if(_state.url.includes('/sign-up')) {
        this.router.navigateByUrl('create-workspace');
      } else if(_state.url.includes('/login')) {
        this.router.navigateByUrl('my-domains');
      }
      return false;
    } else {
      return true;
    }
  }
}

export const GuardRedirectGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  return inject(GuardRedirectionService).canActivate(next, state);
}
