import { RouterModule } from '@angular/router';
import { Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { LayoutOrderSwitcherService } from './core/services/layout-order/layout-order-switcher.service';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterModule, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService]
})
export class AppComponent {

  constructor(public _layoutOrderSwitcherService: LayoutOrderSwitcherService) {}

}

