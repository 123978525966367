import { environment } from "../../../environments/environment";

export class COMMON_CONSTANT {
  public static get API_BASE_URL(): string {
    return `${environment.basePath}`;
  }
  public static get AUTH_BASE_URL(): string {
    return `${environment.authURL}`;
  }
  public static get BASIC_AUTH_TOKKEN(): string {
    return `${environment.basicAuth}`;
  }
}
