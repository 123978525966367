import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable()
export class ToastService {

  constructor(private _messageService: MessageService) { }

  showSuccess(messageBody: Message): void {
    this._messageService.add({severity:'success', summary: messageBody.summary, detail: messageBody.detail });
  }

  showInfo(messageBody: Message): void {
    this._messageService.add({ severity: 'info', summary: messageBody.summary, detail: messageBody.detail });
  }

  showWarn(messageBody: Message): void {
    this._messageService.add({ severity: 'warn', summary: messageBody.summary, detail: messageBody.detail });
  }

  showError(messageBody: Message): void {
    this._messageService.add({severity:'error', summary: messageBody.summary, detail: messageBody.detail});
  }

}
