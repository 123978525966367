import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDebouncedInput]',
  standalone: true
})
export class DebouncedInput {

  private inputs = new Subject<string>();
  @Output() debounceInput = new EventEmitter<string>();

  constructor() {
    this.inputs.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => this.debounceInput.emit(value));
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputEvent = event.target as HTMLInputElement;
    this.inputs.next(inputEvent.value);
  }

}
