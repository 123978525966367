import { Component, OnInit } from '@angular/core';
import { DomainService } from '../../core/services/domain/domain.service';
import { TenantReponse } from '../../shared/interfaces/signup/sign-up.interface';
import { LayoutOrderSwitcherService } from '../../core/services/layout-order/layout-order-switcher.service';

@Component({
  selector: 'app-domain-listing-page',
  standalone: true,
  imports: [],
  templateUrl: './domain-listing-page.component.html',
  styleUrl: './domain-listing-page.component.scss',
  providers: [DomainService]
})
export class DomainListingPageComponent implements OnInit {

  tenantList: TenantReponse[] = [];
  loading: boolean = false;

  constructor(private _domainService: DomainService, private _layoutOrderSwitcherService: LayoutOrderSwitcherService) {}

  ngOnInit(): void {
    this._layoutOrderSwitcherService.setNewOrder(0);
    this.getTenants();
  }

  getTenants() {
    this.loading = true;
    this._domainService.getTenantData().subscribe({
      next: (data) => {
        this.tenantList = data;
      },
      error: (err) => {
        this.loading = false;
        console.error('Error fetching tenant data', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

}
