import { Component } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { AutoFocusModule } from 'primeng/autofocus';
import { HttpParams } from '@angular/common/http';
import { DomainService } from '../../core/services/domain/domain.service';
import { TenantRequest } from '../../shared/interfaces/signup/sign-up.interface';
import { DebouncedInput } from '../../shared/directives/debounceInput/debounced-input.directive';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-createworkspace-page',
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, ButtonModule, AutoFocusModule, DebouncedInput, MessagesModule],
  templateUrl: './createworkspace-page.component.html',
  styleUrl: './createworkspace-page.component.scss',
  providers: [DomainService, MessageService]
})
export class CreateworkspacePageComponent {

  domainFrom!: FormGroup;
  currentStep: number = 0;
  loading: boolean = false;
  isDomainAvail: boolean = false;
  iframeLoaded: boolean = false;
  private lastCheckedDomain: string = '';

  constructor(private _fb: FormBuilder, private _domainService: DomainService, private _messageService: MessageService) {}

  ngOnInit(): void {
    this.constructFrom();
  }

  private constructFrom() {
    this.domainFrom = this._fb.group({
      step1: this._fb.group({
        subdomain:['', Validators.required]
      }),
      step2: this._fb.group({
        domain_name: ['', Validators.required],
      }),
    });
  }

  next(): void {
    if (this.currentStep < 2) {
      this.currentStep++;
    }
  }

  previous(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  isFormValid(): boolean {
    return (this.domainFrom.get('step1.subdomain')?.valid ?? false) && this.isDomainAvail;
  }

  getTenant() {
    const subdomainName = this.domainFrom.get('step1.subdomain')?.value?.trim();
    const isValidDomain = /^[a-zA-Z0-9]+$/.test(subdomainName);
    if (!isValidDomain) {
      this.messageServiceClear();
      this.isDomainAvail = false;
      this._messageService.add({
        severity: 'error',
        summary: 'Invalid Domain Name',
        detail: 'Domain name can only contain letters and numbers.',
      });
      return;
    }

    if (subdomainName && subdomainName !== this.lastCheckedDomain) {
      this.loading = true;
      this.messageServiceClear();
      this._domainService.checkDomainAvailability({subdomain: subdomainName}).subscribe({
        next: (response) => {
          this.lastCheckedDomain = subdomainName;
          this.messageServiceClear();
          if(response.status === 'AVAILABLE') {
            this.isDomainAvail = true;
            this._messageService.add({severity:'success', summary:'Domain Available'});
          } else {
            this.isDomainAvail = false;
            this._messageService.add({severity:'error', summary:'Domain not Available'});
          }
        },
        error: (error) => {
          console.error('Error checking domain availability:', error);
          this.isDomainAvail = false;
          this.lastCheckedDomain = '';
          this.messageServiceClear();
          if (error.status === 400 && error.error.fieldError?.subdomain) {
            const fieldError = error.error.fieldError.subdomain;
            const errorMessage = `${fieldError.message}`;
            this._messageService.add({
              severity: 'error',
              summary: '',
              detail: errorMessage,
            });
          } else {
            this._messageService.add({
              severity: 'error',
              summary: 'Something went wrong',
            });
          }
        },
        complete: () => {
          this.loading = false;
        }
      });
    } else {
      this.messageServiceClear();
    }
  }

  onSubmit() {
    if(this.domainFrom.valid){
      this.loading = true;
      this.domainFrom.disable();
      const tenantData: TenantRequest = {
        subdomain: this.domainFrom.get('step1.subdomain')?.value,
        name: this.domainFrom.get('step2.domain_name')?.value
      };
      this._domainService.addTenant(tenantData).subscribe({
        next: response => {
          if(response.id && response.subdomain) {
            this.getShortToken(response.subdomain);
          }
        },
        error: error => {
          this.loading = false;
          console.error('Failed to add tenant:', error);
          this.domainFrom.enable();
        }
      });
    }
  }

  private getShortToken(subDomain: string) {
    this._domainService.requestCrossToken({'subdomain': subDomain}).subscribe({
      next: response => {
       if(response.shortToken) {
        this.navigateToSubdomainWithToken(subDomain, response.shortToken);
       }
      },
      error: error => {
        this.loading = false;
        console.error('Failed to add tenant:', error);
        this.domainFrom.enable();
      },
      complete: () => {
        this.domainFrom.enable();
      }
    });
  }

  public navigateToSubdomainWithToken(subdomain: string, token: string): void {
    const url = this.constructSubdomainURL(subdomain);
    const params = new HttpParams().set('shortToken', token);
    const fullUrl = `${url}?${params.toString()}`;
    this.loading = false;
    this.openSubdomainInNewTab(fullUrl);
  }

  private openSubdomainInNewTab(url: string): void {
    window.open(url, '_self');
  }

  private constructSubdomainURL(subdomain: string): string {
    // return `http://localhost:4201`;
    return `https://${subdomain}.tackit.in/login`;
  }

  private messageServiceClear() {
    this._messageService.clear();
  }

}
