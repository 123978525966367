import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LayoutOrderSwitcherService {

  private layoutOrder = signal<0 | 1>(0);

  constructor() { }

  get Order() {
    return this.layoutOrder;
  }

  setNewOrder(order: 0 | 1) : void {
    this.layoutOrder.update(() => order);
  }

}
