import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputOtpModule } from 'primeng/inputotp';
import { DomainService } from '../../core/services/domain/domain.service';
import { MaskEmailPipe } from '../../shared/pipes/maskEmail/mask-email.pipe';
import { SessionService } from '../../core/services/session/session.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { matchPasswordsValidator } from '../../shared/validators/confirm-password.validator';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { LayoutOrderSwitcherService } from '../../core/services/layout-order/layout-order-switcher.service';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-signup-page',
  standalone: true,
  imports: [ButtonModule, FormsModule, ReactiveFormsModule, AutoFocusModule, MaskEmailPipe, InputOtpModule, RouterLink,CommonModule],
  templateUrl: './signup-page.component.html',
  styleUrl: './signup-page.component.scss',
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(300)),
    ])
  ],
  providers: [DomainService, SessionService]
})

export class SignupPageComponent implements OnInit {

  step: number = 1;
  otpFrom!: FormGroup;
  loading: boolean = false;
  maskedEmail: string = '';
  signUpToken: string = '';
  onBoardingForm!: FormGroup;
  otpStatus: boolean = false;
  errorMessage=''
  errorMessageEmail=''

  constructor(private _fb: FormBuilder, private _domainService: DomainService, private _sessionService: SessionService, private _router: Router, private _layoutOrderSwitcherService: LayoutOrderSwitcherService) {}

  ngOnInit(): void {
    this._layoutOrderSwitcherService.setNewOrder(0);
    this.constructFrom();
  }

  constructFrom() {
    this.onBoardingForm = this._fb.group({
      firstName: ['', Validators.required],
      lastName: [''],
      email: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    }, { validator: matchPasswordsValidator('password', 'confirm_password') });

    this.otpFrom = this._fb.group({
      verificationCode: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  goBack() {
    if (this.step > 1) {
      this.step--;
      this.otpFrom.reset();
    }
  }

  onSubmit() {
    if(this.step === 1 && this.onBoardingForm.valid) {
      this.loading = true;
      this.onBoardingForm.disable();
      const { confirm_password, ...formData } = this.onBoardingForm.value;
      this._domainService.signup(formData).subscribe({
        next: (res) => {
          this.step++;
          this.signUpToken = res.token;
        },
        error: (err) => {
          console.error(err);
          this.onBoardingForm.enable();
          this.loading = false;
          if (err.status === 400 && err.error?.detail === "User already exists with this email") {
            this.errorMessageEmail = "A user already exists with this email. Please use a different email address.";
          } else {
            this.errorMessageEmail = "An error occurred. Please try again.";
          }
        },
        complete: () => {
          this.onBoardingForm.enable();
          this.loading = false;
        }
      });
    } else if(this.step === 2 && this.otpFrom.valid) {
      this.loading = true;
      this.otpFrom.disable();
      this._domainService.verifyAccount({token: this.signUpToken, ...this.otpFrom.value}).subscribe({
        next: (res) => {
          if(res.success) {
            this._sessionService.createSession(res.accessToken, res.refreshToken);
            this._router.navigate(['create-workspace']);
          } else {
            if (res.error === 'invalid verification code') {
              this.errorMessage = 'Invalid OTP. Please verify again.';
            } else {
              console.log(res.error);
            }
          }
        },
        error: (err) => {
          console.error(err);
          this.otpFrom.enable();
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
          this.otpFrom.enable();
        }
      });
    }
  }

}
