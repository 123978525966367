<div class="h-full mainWrap">
  <div class="h-full w-full">
    <div class="grid grid-nogutter grid-container w-full h-screen">
      <div class="leftWrap" [class.flex-order-0]="_layoutOrderSwitcherService.Order() === 0" [class.flex-order-1]="_layoutOrderSwitcherService.Order() === 1">
        <div class="grid align-content-center justify-content-center w-full h-full">
          <div class="content grid h-full justify-content-between ">

            <div class="header">
              <img src="/assets/images/track-it-logo.svg" class="logo" alt="Logo" loading="lazy">
            </div>

            <router-outlet></router-outlet>

          </div>
        </div>
      </div>
      <div class="rightWrap grid flex-column align-items-center">
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
