<div class="setup_sub_domain">
  <div class="flex flex-column gap-2">
    <h1 class="font-medium">Get your Custom Tackit URL</h1>
    <p class="my-1">This is the URL where you and your team will access your workspace. You can always update your custom URL later</p>
  </div>

  <form class="mt-3" [formGroup]="domainFrom" (ngSubmit)="onSubmit()">
    <div class="formgrid grid">

      @if(currentStep === 0) {
        <div class="field col-12" formGroupName="step1">
          <label for="subdomain" class="text-sm">Choose your domain <span class="text-red-500">*</span></label>
          <div class="flex customDomain align-items-center border-round bg-white text-base text-color surface-overlay pl-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <input type="search" pAutoFocus [autofocus]="true" [formControlName]="'subdomain'" class="h-full appearance-none outline-none border-none border-0 w-full text-color surface-overlay pr-2" (debounceInput)="getTenant()" appDebouncedInput>
            <div class="suffix bg-gray-300 px-2 h-full flex align-items-center">
              .trackit.in
            </div>
          </div>
        </div>
        <div class="w-full domain_check_notice col-12">
          <p-messages />
        </div>
        <div class="flex justify-content-end align-items-center mt-2 w-full">
          <p-button [loading]="loading" label="Next" icon="pi pi-arrow-right" iconPos="right" [raised]="true" [disabled]="!isFormValid()" (click)="next()" />
        </div>
      }

      @if(currentStep === 1) {
        <div formGroupName="step2" class="w-full">
          <div class="field col-12">
            <label for="domain_name" class="text-sm">Domain Name <span class="text-red-500">*</span></label>
            <input type="text" pAutoFocus [autofocus]="true" [formControlName]="'domain_name'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </div>
        </div>
        <div class="flex justify-content-between align-items-center mt-2 w-full">
          <p-button label="Previous" (click)="previous()" icon="pi pi-arrow-left" iconPos="left" [raised]="true" [disabled]="!domainFrom.get('step1')?.get('subdomain')?.valid" />
          <p-button type="submit" [loading]="loading" label="Submit" icon="pi pi-arrow-right" iconPos="right" [raised]="true" [disabled]="!domainFrom.get('step2')?.get('domain_name')?.valid" />
        </div>
      }

    </div>
  </form>

</div>
