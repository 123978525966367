import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { COMMON_CONSTANT } from '../../constants/common-keys.constants';
import { AuthRequest, AuthResponse } from '../../../shared/interfaces/login/login.interface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${COMMON_CONSTANT.BASIC_AUTH_TOKKEN}`,
    })
  }

  constructor(private _http: HttpClient) { }

  login(requestPayload: AuthRequest):Observable<AuthResponse>{
    const body = new HttpParams()
    .set('grant_type', 'custom_password')
    .set('username', requestPayload.username)
    .set('password', requestPayload.password);
    return this._http.post<AuthResponse>(`${COMMON_CONSTANT.AUTH_BASE_URL}/oauth2/token`, body.toString(), this.httpOptions);
  }

  refreshAccessToken(data: FormData) : Observable<AuthResponse> {
    return this._http.post<AuthResponse>(`${COMMON_CONSTANT.API_BASE_URL}/oauth/token`, data, this.httpOptions)
  }

}
