import { Injectable } from '@angular/core';
import { STORAGE_KEYS } from '../../constants/storage-keys.constants';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor() { }

  setAccessToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.accessToken, token);
  }

  setRefreshToken(token: string): void {
    localStorage.setItem(STORAGE_KEYS.refreshToken, token);
  }

  setLoggedIn(isLoggedIn: boolean) : void {
    localStorage.setItem(STORAGE_KEYS.IsLoggedIn, isLoggedIn ? "true" : "false");
  }

  getAccessToken() : string | null {
    return localStorage.getItem(STORAGE_KEYS.accessToken);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(STORAGE_KEYS.refreshToken);
  }

  createSession(accessToken: string, refreshToken: string): void {
    this.setLoggedIn(true);
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  updateSession = (accesstoken:string, refreshToken?: string): void  =>{
    if(refreshToken){
      localStorage.setItem(STORAGE_KEYS.refreshToken, refreshToken);
    }
    this.setLoggedIn(true);
    localStorage.setItem(STORAGE_KEYS.accessToken, accesstoken);
  }

  clearSession(): void {
    localStorage.removeItem(STORAGE_KEYS.accessToken);
    localStorage.removeItem(STORAGE_KEYS.refreshToken);
    localStorage.setItem(STORAGE_KEYS.IsLoggedIn,"false");
  }

}
