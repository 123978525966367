import {Component, OnInit} from '@angular/core';
import {DomainService} from '../../core/services/domain/domain.service';
import {TenantReponse} from '../../shared/interfaces/signup/sign-up.interface';
import {LayoutOrderSwitcherService} from '../../core/services/layout-order/layout-order-switcher.service';
import {AvatarModule} from 'primeng/avatar';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {MenuItem} from 'primeng/api';
import {NgStyle} from '@angular/common';
import {Router} from '@angular/router';
import {SessionService} from '../../core/services/session/session.service';
import {Button} from 'primeng/button';
import {HttpParams} from '@angular/common/http';
import {CardModule} from 'primeng/card';

@Component({
  selector: 'app-domain-listing-page',
  standalone: true,
  imports: [
    AvatarModule,
    TieredMenuModule,
    NgStyle,
    Button,
    CardModule,
  ],
  templateUrl: './domain-listing-page.component.html',
  styleUrl: './domain-listing-page.component.scss',
  providers: [DomainService]
})
export class DomainListingPageComponent implements OnInit {

  tenantList: TenantReponse[] = [];
  loading: boolean = false;
  items: MenuItem[] = [];
  initials: string = 'K';
  subDomain: string = '';

  constructor(private _domainService: DomainService, private _layoutOrderSwitcherService: LayoutOrderSwitcherService, private router: Router, private sessionService: SessionService) {
  }

  ngOnInit(): void {
    this._layoutOrderSwitcherService.setNewOrder(0);
    this.getTenants();
    this.items = [
      {
        label: 'Logout',
        icon: 'pi pi-sign-out',
        command: () => this.logout(),
      },
    ];
  }
  logout() {
    console.warn('logging out...')
    this.sessionService.clearSession();
    this.router.navigate(['/login']);
  }
  getTenants() {
    this.loading = true;
    this._domainService.getTenantData().subscribe({
      next: (data) => {
        this.tenantList = data;

        this.subDomain = data[0]?.subdomain || '';
        console.log('Subdomain:', this.subDomain);

      },
      error: (err) => {
        this.loading = false;
        console.error('Error fetching tenant data', err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  getShortToken() {
    if (!this.subDomain) {
      console.error('Subdomain not available');
      return;
    }

    this._domainService.requestCrossToken({'subdomain': this.subDomain}).subscribe({
      next: response => {
        if (response.shortToken) {
          console.log('New Short Token Generated: ', response.shortToken);
          this.navigateToSubdomainWithToken(this.subDomain, response.shortToken);
        }
      },
      error: error => {
        this.loading = false;
        console.error('Failed to add tenant:', error);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  public navigateToSubdomainWithToken(subdomain: string, token: string): void {
    const url = this.constructSubdomainURL(subdomain);
    const params = new HttpParams().set('shortToken', token);
    const fullUrl = `${url}?${params.toString()}`;
    this.loading = false;
    this.openSubdomainInNewTab(fullUrl);
  }

  private openSubdomainInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  private constructSubdomainURL(subdomain: string): string {
    return `https://${subdomain}.tackit.in/login`;
  }
}
