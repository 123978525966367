<div class="fromWrap">
  @if(step === 1) {
    <div class="flex flex-column gap-2">
      <h1 class="font-medium">Lets get Started</h1>
      <p class="my-1">Create an account</p>
    </div>

      <form class="mt-3" [formGroup]="onBoardingForm" (ngSubmit)="onSubmit()">
        <div class="formgrid grid">
          <div class="field col-6">
            <label for="firstname" class="text-sm">Firstname <span class="text-red-500">*</span> </label>
            <input pAutoFocus [autofocus]="true" type="text" pInputText  [formControlName]="'firstName'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
          </div>
          <div class="field col-6">
            <label for="lastname" class="text-sm">Lastname</label>
            <input type="text" pInputText [formControlName]="'lastName'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </div>
          <div class="field col-12">
            <label for="email" class="text-sm">Company email <span class="text-red-500">*</span></label>
            <input type="email" [formControlName]="'email'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </div>
          <div class="field col-6 mb-1">
            <label for="password" class="text-sm">Password <span class="text-red-500">*</span></label>
            <input type="password" [formControlName]="'password'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </div>
          <div class="field col-6 mb-1">
            <label for="confirm_password" class="text-sm">Confirm password <span class="text-red-500">*</span></label>
            <input type="password" [formControlName]="'confirm_password'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
          </div>
          <div class="field col-12 my-1">
            @if(onBoardingForm.get('confirm_password')?.dirty && onBoardingForm.errors?.['passwordMismatch']) {
              <small class="error text-red-700 flex gap-1 align-items-center"><i class="pi pi-exclamation-circle border-circle text-white bg-red-700"></i>Passwords must match!</small>
            }
          </div>
        </div>
        <div *ngIf="errorMessageEmail" class="text-red-500 mt-2">{{ errorMessageEmail }}</div>
        <div class="flex justify-content-end align-content-center mt-2">
          <p-button type="submit" [loading]="loading" label="Create Account" [raised]="true" class="w-full content-full" [disabled]="!onBoardingForm.valid" />
        </div>
        <p class="text-center my-2">Already have a account? <a routerLink="/login" class="underline text-blue-600">Login</a></p>
      </form>
  } @else if(step === 2) {
    <div class="flex flex-column gap-2" @fadeInOut>
      <div class="backStep mb-2">
        <button (click)="goBack()" class="hover:border-bluegray-900 hover:text-bluegray-900 pi pi-arrow-left border-circle border-1 border-solid border-bluegray-500 cursor-pointer p-2"></button>
      </div>
      <h1 class="font-medium">Verify Code</h1>
      <p class="my-1">We’ve sent a code to {{ onBoardingForm.get('company_email')?.value | maskEmail }}</p>
    </div>

    <form class="mt-3" [formGroup]="otpFrom" (ngSubmit)="onSubmit()">
      <div class="formgrid grid">
        <div class="field col-12">
          <p-inputOtp pAutoFocus [autofocus]="true" [class.invalid-otp]="otpStatus" class="flex justify-content-between" [mask]="false" [length]="6" [integerOnly]="true" [formControlName]="'verificationCode'" />
        </div>
      </div>
      <div *ngIf="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</div>
      <div class="flex justify-content-end align-content-center mt-2">
        <p-button type="submit" [loading]="loading" label="Verify" [raised]="true" class="w-full content-full" [disabled]="!otpFrom.valid" />
      </div>
    </form>
  }
</div>

