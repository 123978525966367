<div class="fromWrap">
  <div class="flex flex-column gap-2">
    <h1 class="font-medium">Welcome Back</h1>
    <p class="my-1">Login to your account</p>
  </div>
  <form class="mt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="username" class="text-sm">Username <span class="text-red-500">*</span> </label>
        <input pAutoFocus [autofocus]="true" type="email" [formControlName]="'username'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
      </div>
      <div class="field col-12">
        <label for="password" class="text-sm">Password <span class="text-red-500">*</span> </label>
        <input type="password" [formControlName]="'password'" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
      </div>
    </div>
    <div *ngIf="errorMessage" class="text-red-500 mt-2">
      {{ errorMessage }}
    </div>
    <div class="flex justify-content-end align-content-center mt-2">
      <p-button type="submit" [loading]="loading" label="Login" [raised]="true" class="w-full content-full" [disabled]="!loginForm.valid" />
    </div>
    <p class="text-center my-2">Don't have an account yet? <a routerLink="/sign-up" class="underline text-blue-600">Sign up</a></p>
  </form>
</div>
